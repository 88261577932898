import { GetObjectsResponse } from '@algolia/client-search'

import { AlgoliaLot, AlgoliaLotIndices } from '@nx/global-types'

import { AlgoliaClientInstance } from '../algoliaClient'

export type AlgoliaLotsByObjectIDsResponse = GetObjectsResponse<AlgoliaLot>

export function getAlgoliaLotsByObjectIDs({
  getAlgoliaClient,
}: AlgoliaClientInstance) {
  return async ({
    index = 'lots_virtual_sort_lotno_asc',
    objectIDs,
  }: GetAlgoliaLotsByObjectIDsProps): Promise<AlgoliaLotsByObjectIDsResponse> => {
    return getAlgoliaClient()
      .initIndex(index)
      .getObjects<AlgoliaLot>(
        [objectIDs]
          .flat()
          .filter(Boolean)
          .map((objectID) => objectID.toString())
      )
  }
}

export interface GetAlgoliaLotsByObjectIDsProps {
  index?: AlgoliaLotIndices
  objectIDs: string[] | number[] | number | string
}
